import React, { Component } from "react";
import ProfilePic from "./../img/sabina.jpg";

export default class SabinaCV extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="cv-bg">
          <section className="cv-container">
            <section className="page">
              <section className="intro">
                <img src={ProfilePic} alt="profile pic" className="photo" />
                <div className="name-and-summary">
                  <p className="name">Sabina Timilsina</p>
                  <p className="title">
                    Frontend Developer / ReactJS Developer
                  </p>
                  <p className="summary">
                    Versatile ReactJS developer with two years of experience in
                    designing useful and approachable user interfaces.
                    Demonstrated excellent skills in ReactJS, NextJS, SCSS, CSS,
                    Bootstrap, and HTML. True team player with strengths in
                    adaptability and accuracy.
                  </p>
                </div>
              </section>
              <section className="details">
                <span>
                  <i className="fas fa-map-marker-alt"></i> Jawalakhel, Lalitpur
                </span>
                <span>
                  <a
                    className="no-decoration"
                    href="https://www.linkedin.com/in/sabinatimilsina/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fab fa-linkedin"></i> Sabina Timilsina{" "}
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                </span>
                <span>
                  <i className="far fa-envelope"></i> sabinatimsina18@gmail.com
                </span>
                <span>
                  <i className="fas fa-mobile-alt"></i> +977-9869175106
                </span>
              </section>
              <section className="experience">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Experience</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">ReactJS Developer</p>
                  <p>
                    <i class="fas fa-building"></i> New IT Venture Corporation
                    Limited, Kathmandu
                  </p>
                  <p>
                    <i className="far fa-clock"></i> Dec, 2019 - Dec 2020
                  </p>
                  <p>
                    <i class="fas fa-user-tag"></i> Designer, Developer
                  </p>
                  <p>
                    <i class="fas fa-code"></i> VanillaJS, ReactJS, NextJS,
                    HTML, SCSS, Bootstrap
                  </p>
                  <p>
                    <i class="fas fa-tools"></i> SDLC, Agile Methodologies,
                    Research, Troubleshooting, Git, RESTful APIs
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">Internship</p>
                  <p>
                    <i class="fas fa-building"></i> Pro-Mech Minds & Engineering
                    Services Pvt. Ltd., Lalitpur
                  </p>
                  <p>
                    <i className="far fa-clock"></i> Dec, 2018 - Apr 2019
                  </p>
                  <p>
                    <i class="fas fa-user-tag"></i> Developer, Junior Business
                    Analyst
                  </p>
                  <p>
                    <i class="fas fa-code"></i> Python, Django
                  </p>
                  <p>
                    <i class="fas fa-tools"></i> UML Diagrams, Agile
                    Methodologies, Code Debugging, REST API
                  </p>
                </div>
              </section>
              <section className="projects">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Projects</div>
                  <div className="line right"></div>
                </div>
                <div className="two-col-grid">
                  <div className="content">
                    <p className="sub-heading">More Music More Movies (MOMO)</p>
                    <p>
                      <a
                        href="https://momo.musicnepal.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-globe"></i> momo.musicnepal.com{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i class="fas fa-building"></i> New IT Venture
                      &nbsp;&nbsp;&nbsp;
                      <i className="far fa-clock"></i> Sep, 2020 - Dec, 2020
                    </p>
                    <p>
                      <i class="fas fa-user-tag"></i> Designer, Developer
                    </p>
                    <p>
                      <i class="fas fa-code"></i> ReactJS, HTML, CSS, Bootstrap
                    </p>
                    <p>
                      <i class="fas fa-info-circle"></i> Web Application for
                      Music Nepal.
                    </p>
                  </div>
                  <div className="content">
                    <p className="sub-heading">DG Link Network</p>
                    <p>
                      <a
                        href="https://6129c6b91630d0c7108a58f2--nostalgic-knuth-15a73e.netlify.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-globe"></i> Website Link{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i class="fas fa-building"></i> Freelance
                      &nbsp;&nbsp;&nbsp;
                      <i className="far fa-clock"></i> Jul, 2021 - Ongoing
                    </p>
                    <p>
                      <i class="fas fa-user-tag"></i> Designer, Developer,
                      Maintainer
                    </p>
                    <p>
                      <i class="fas fa-code"></i> ReactJS, NextJS, NodeJS, SCSS,
                      Bootstrap
                    </p>
                    <p>
                      <i class="fas fa-info-circle"></i> Website for DG Link
                      Network.
                    </p>
                  </div>
                  <div className="content">
                    <p className="sub-heading">Timsina Foundation</p>
                    <p>
                      <a
                        href="https://www.timsinafoundation.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-globe"></i> timsinafoundation.org{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i class="fas fa-building"></i> Freelance
                      &nbsp;&nbsp;&nbsp;
                      <i className="far fa-clock"></i> Jun, 2021 - Ongoing
                    </p>
                    <p>
                      <i class="fas fa-user-tag"></i> Designer, Developer,
                      Maintainer
                    </p>
                    <p>
                      <i class="fas fa-code"></i> Wordpress, HTML, CSS
                    </p>
                    <p>
                      <i class="fas fa-info-circle"></i> Web Application for
                      Progress Tech Solutions
                    </p>
                  </div>
                  <div className="content">
                    <p className="sub-heading">Nepal Television</p>
                    <p>
                      <a
                        href="https://ntv.org.np/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-globe"></i> ntv.org.np{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i class="fas fa-building"></i> New IT Venture
                      &nbsp;&nbsp;&nbsp;
                      <i className="far fa-clock"></i> May, 2020 - Aug, 2020
                    </p>
                    <p>
                      <i class="fas fa-user-tag"></i> Designer, Developer
                    </p>
                    <p>
                      <i class="fas fa-code"></i> ReactJS, HTML, CSS, Bootstrap
                    </p>
                    <p>
                      <i class="fas fa-info-circle"></i> Website (Older Version)
                      for NTV.
                    </p>
                  </div>
                </div>
              </section>
              <section className="participation">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Particiaption</div>
                  <div className="line right"></div>
                </div>
                <div className="two-col-grid">
                  <div className="content">
                    <p className="sub-heading">Yatra 1.0</p>
                    <p>
                      <a
                        href={process.env.PUBLIC_URL + "/certificate/yatra.jpg"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-certificate"></i> Certificate of
                        Appreciation - Organizer{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i className="fas fa-university"></i> NCE IT Club & NCE
                      Robotics Club
                    </p>
                    <p>
                      <i className="far fa-clock"></i> February 2018
                    </p>
                  </div>
                  <div className="content">
                    <p className="sub-heading">Python Django Workshop</p>
                    <p>
                      <a
                        href={
                          process.env.PUBLIC_URL + "/certificate/django.jpg"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-certificate"></i> Certificate of
                        Appreciation - Participant{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i className="fas fa-university"></i> Kathmandu University
                      Computer Club (KUCC)
                    </p>
                    <p>
                      <i className="far fa-clock"></i> January 2017
                    </p>
                  </div>
                  <div className="content">
                    <p className="sub-heading">Miss-Tech 2016: Aquathon</p>
                    <p>
                      <a
                        href={
                          process.env.PUBLIC_URL + "/certificate/miss-tech.jpg"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fas fa-certificate"></i> Certificate of
                        Appreciation - Participant{" "}
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </p>
                    <p>
                      <i className="fas fa-university"></i> Robotics Association
                      of Nepal (RAN)
                    </p>
                    <p>
                      <i className="far fa-clock"></i> December 2016
                    </p>
                  </div>
                </div>
              </section>
            </section>
            <section className="page">
              <section className="education">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Education</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">
                    Bachelor in Computer Engineering (IOE - TU)
                  </p>
                  <p>
                    <i className="fas fa-university"></i> National College of
                    Engineering, Lalitpur
                  </p>
                  <p>
                    <i className="fas fa-graduation-cap"></i> 2018 A.D.
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">+2 Science (H.S.E.B.)</p>
                  <p>
                    <i className="fas fa-university"></i> St.Mary’s Higher
                    Secondary School, Pokhara
                  </p>
                  <p>
                    <i className="fas fa-graduation-cap"></i> 2014 A.D.
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">
                    School Leaving Certificate (N.E.B.)
                  </p>
                  <p>
                    <i className="fas fa-university"></i> Shree Amarsingh Model
                    Secondary School, Pokhara
                  </p>
                  <p>
                    <i className="fas fa-graduation-cap"></i> 2012 A.D.
                  </p>
                </div>
              </section>
              <section className="training">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Training</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">
                    WordPress Webpage Development and Web Hosting
                  </p>
                  <p>
                    <i className="fas fa-university"></i> National College of
                    Engineering, Lalitpur
                  </p>
                  <p>
                    <i className="far fa-clock"></i> June 2015
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">Data Science, Django and Python</p>
                  <p>
                    <i className="fas fa-university"></i> Deerwalk Institute of
                    Technology, Kathmandu
                  </p>
                  <p>
                    <i className="far fa-clock"></i> Nov, 2017 - Feb, 2018
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">
                    Core Java, Object-Oriented Programming
                  </p>
                  <p>
                    <i className="fas fa-university"></i> Majhaura Educational
                    Agency, Satdobato
                  </p>
                  <p>
                    <i className="far fa-clock"></i> May, 2016 - Aug, 2016
                  </p>
                </div>
                <div className="content">
                  <p className="sub-heading">
                    Quality Assurance (Online Training)
                  </p>
                  <p>
                    <i className="fas fa-university"></i> Progress Tech
                    Solutions, Texas
                  </p>
                  <p>
                    <i className="far fa-clock"></i> June 2019
                  </p>
                </div>
              </section>
              <section className="tech-skills">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Technical Skills</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">Key Skills</p>
                  <span>ReactJS</span>
                  <span>NextJS</span>
                  <span>HTML5</span>
                  <span>CSS3</span>
                  <span>SCSS</span>
                  <span>Bootstrap</span>
                  <span>Material UI</span>
                  <span>Git Version Control</span>
                </div>
                <div className="content">
                  <p className="sub-heading">Additional Skills</p>
                  <span>Core Java</span>
                  <span>C</span>
                  <span>C++</span>
                  <span>DBMS - SQL</span>
                  <span>Django</span>
                  <span>QA</span>
                  <span>Selenium</span>
                </div>
              </section>
              <section className="other-skills">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Other Skills</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">General Skills</p>
                  <span>Agile Development</span>
                  <span>Report Writing</span>
                  <span>Presentation</span>
                  <span>Microsoft Word</span>
                  <span>Microsoft Power Point</span>
                </div>
                <div className="content">
                  <p className="sub-heading">Soft Skills</p>
                  <span>Creativity</span>
                  <span>Commitment</span>
                  <span>Team Work</span>
                  <span>Communication</span>
                  <span>Critical Thinking</span>
                  <span>Decision Making</span>
                  <span>Self-Motivation</span>
                  <span>Work Under Pressure</span>
                </div>
              </section>
              <section className="interests">
                <div className="heading-with-line">
                  <div className="line left"></div>
                  <div className="heading">Interests</div>
                  <div className="line right"></div>
                </div>
                <div className="content">
                  <p className="sub-heading">Interests</p>
                  <span>Coding</span>
                  <span>Reading</span>
                  <span>Travelling</span>
                  <span>Hiking</span>
                  <span>Trekking</span>
                </div>
              </section>
              <section className="website">
                <div>
                  <hr />
                  <a href="https://www.sabinatimilsina.com.np/">
                    <i class="fas fa-globe"></i> www.sabinatimilsina.com.np{" "}
                    <i class="fas fa-external-link-alt"></i>
                  </a>{" "}
                  <hr />
                </div>
              </section>
            </section>
          </section>
        </section>
      </React.Fragment>
    );
  }
}
