import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./components/HomePage";
import SabinaCV from "./components/SabinaCV";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage}></Route>
        <Route exact path="/cv" component={SabinaCV}></Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
