import React, { useState } from "react";
import Popup from "./Popup.js";
import BG from "./../img/bg.jpg";
import ProfilePic from "./../img/sabina.jpg";
import { Link } from "react-router-dom";

function Home() {
  const [popup, setPopup] = useState(false);

  const handleClick = () => {
    // setPopup(true);
    if (!popup) setPopup(true);
    else setPopup(false);
  };
  return (
    <React.Fragment>
      <img className="background" src={BG} alt="background" />
      <section className="home-container">
        <h1>Sabina&apos;s Portfolio</h1>
        <section className="profile-card-wrapper">
          <div className="profile-card">
            <div className="pic-and-name">
              <img src={ProfilePic} alt="pic" className="profile-pic" />
              <h3>Sabina Timilsina</h3>
            </div>
            <hr />
            <p>Hi! I am Sabina.</p>
            <p>
              I am a ReactJS developer with more than two years of experience in
              designing useful and approachable user interfaces.
            </p>
            <p>Here's my resume.</p>
            <div className="resume-link-wrapper">
              <Link className="resume-link" to="/cv">
                <i className="far fa-file-alt"></i>&nbsp; My Resume
              </Link>
            </div>
          </div>
        </section>
        <div></div>
        <div></div>
        <section className="footer">
          <span onClick={handleClick}>
            <i className="fas fa-info-circle"></i>&nbsp; Website Credits
          </span>
        </section>
      </section>
      {popup ? <Popup passClick={handleClick} /> : ""}
    </React.Fragment>
  );
}

export default Home;
