import React from "react";

function Popup(props) {
  return (
    <section className="popup-container">
      <div className="popup-wrapper">
        <div className="popup">
          <h3>Website Credits</h3>
          <hr />
          <p>
            <span>
              Designer:{" "}
              <a
                href="https://prashandip.com.np/"
                target="_blank"
                rel="noreferrer"
              >
                Prashandip <i className="far fa-arrow-alt-circle-right"></i>
              </a>{" "}
            </span>
          </p>
          <p>
            <span>
              Background:{" "}
              <a
                className="footer-link"
                href="https://www.pexels.com/photo/photo-of-a-laptop-and-a-tablet-on-the-table-2528118/"
                target="_blank"
                rel="noreferrer"
              >
                Elvis <i className="far fa-arrow-alt-circle-right"></i>
              </a>
            </span>
          </p>
          <p>
            <span>Developers: Sabina, Prashandip</span>
          </p>
          <span onClick={props.passClick} className="close-button">
            &times;
          </span>
        </div>
      </div>
    </section>
  );
}

export default Popup;
